export const createButtonTypes = {
	default: 'default',
	loading: 'loading',
	ready: 'ready'
}

export const complaintToModeratorText = 'Модераторы рассмотрят ваше обращение в течение 24 часов'

export const subscriptionsParams = {
	likesForSubscription: 3,
	defaultSubscriptionsCount: 1
}
