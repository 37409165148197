<template>
	<div class="post-photo-component post-media-data">
		<cropper-wrapper
			:preview-cropper="previewCropper"
			:is-desktop="true"
			@crop="onCrop($event)"
			@changePhoto="onChangePhoto"
			v-if="showCropModal && !isMobileDevice && !heicProccess"
		/>
		<div v-if="heicProccess" class="pagination-loader">
			<i class="i-spinner rotating" />
		</div>
		<div v-show="!showCropModal || isMobileDevice" class="relative">
			<div :class="['embed-responsive', 'preview-blur-wrap', { 'embed-responsive-3by4': checkAspectRatio }]">
				<div class="embed-responsive-item">
					<upload-image-preview @load="onLoadEnd" v-if="preview" :src="preview" />
					<img class="preview-blur" v-if="preview && checkAspectRatio" :src="preview" alt="" />
				</div>
			</div>
			<input-media
				v-show="!showCropModal"
				ref="mediaInput"
				v-model="post.photo"
				@load="newImageLoaded"
				@error="onError($event)"
				:rules="{ minDimensions: [1000, 217] }"
				format-file="image/*, .heic, .heif"
			/>

			<div v-if="preview && !showCropModal" class="create-post-image-controls">
				<button type="button" class="create-post-control" @click="openCropModal">
					<i class="i-pen" />
					<span>Редактировать</span>
				</button>
			</div>
		</div>
		<dialog-component
			v-if="showCropModal && isMobileDevice && !heicProccess"
			class="inner-dialog"
			@close="hideCropModal"
			cross-class="hide-cross"
		>
			<template #header-title>Редактирование фото</template>
			<cropper-wrapper :preview-cropper="previewCropper" @crop="onCrop($event)" @changePhoto="onChangePhoto" />
		</dialog-component>
	</div>
</template>

<script>
import { mapActions, mapState, mapWritableState } from 'pinia'
import { useCreatePostStore } from '@/stores/createPost'
import { useCommonStore } from '@/stores/common'
import ModalState from '@/helpers/modalState'
import { useModalStore } from '@/stores/modal'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import UploadImagePreview from '@/components/UploadImagePreview.vue'
import InputMedia from '@/components/create-post/InputMedia.vue'
import CropperWrapper from '@/components/create-post/CropperWrapper.vue'

const modalState = new ModalState()

const validationErrorDescriptions = {
	minDimensions: 'Выбери другое фото.',
	size: 'Попробуй уменьшить размер в каком-нибудь приложением.',
	imageExt: 'Переделай в один из этих форматов в каком-нибудь приложении.'
}

export default {
	name: 'CreatePhotoMedia',
	components: {
		DialogComponent,
		UploadImagePreview,
		InputMedia,
		CropperWrapper
	},
	computed: {
		...mapWritableState(useCreatePostStore, ['croppedBase64', 'fullBase64', 'checkAspectRatio', 'heicProccess']),
		...mapState(useCreatePostStore, ['photoAsFile', 'isCreating', 'post']),
		...mapState(useModalStore, ['showCropModal']),
		...mapState(useCommonStore, ['isMobileDevice']),
		preview() {
			return this.previewSrc()
		},
		previewCropper() {
			return this.previewSrc(true)
		}
	},
	methods: {
		...mapActions(useCreatePostStore, ['showValidationError', 'resetPhoto']),
		...mapActions(useModalStore, ['openCropModal', 'closeCropModal', 'setResizePopUp']),
		onCrop(e) {
			this.hideCropModal()
			this.post.crop = e
		},
		previewSrc(forCropper = false) {
			if (this.croppedBase64 && !forCropper) {
				return this.croppedBase64
			}
			if (typeof this.post.photo === 'string') {
				const t = forCropper ? `?t=${Date.now()}` : ''
				return `${this.post.photo}${t}`
			}
			if (this.fullBase64 && forCropper) {
				return this.fullBase64
			}
			return ''
		},
		onChangePhoto() {
			this.$refs.mediaInput.$refs.media.click()
		},
		onError(error) {
			const errorKey = Object.keys(error.failedRules)[0]
			this.showValidationError(error.failedRules[errorKey], validationErrorDescriptions[errorKey])
		},
		newImageLoaded() {
			this.post.photo_id = null
		},
		onLoadEnd() {
			this.heicProccess = false
			if (this.$refs.previewImg) {
				const aspectRatio = this.$refs.previewImg.naturalWidth / this.$refs.previewImg.naturalHeight
				const naturalWidth =
					aspectRatio < 0.75
						? this.$refs.previewImg.naturalWidth + this.$refs.previewImg.naturalWidth * (1 - aspectRatio)
						: this.$refs.previewImg.naturalWidth
				this.setResizePopUp({ naturalWidth, naturalHeight: this.$refs.previewImg.naturalHeight }, 416)
			}
		},
		hideCropModal() {
			this.closeCropModal()
			this.$nextTick(() => {
				modalState.openModal(this.$el)
			})
		}
	}
}
</script>

<style scoped lang="scss">
.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
	&::before {
		display: block;
		content: '';
	}
	&-item {
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
	&-3by4 {
		@media (max-width: $screen-desktop) {
			&::before {
				padding-top: 133%;
			}
			& .embed-responsive-item {
				position: absolute;
			}
		}
	}
}
.preview {
	&-blur {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		filter: blur(5px);
		&-wrap {
			overflow: hidden;
			border-radius: 12px;
			@media (min-width: $screen-desktop) {
				border-radius: 0;
			}
		}
		@media (min-width: $screen-desktop) {
			object-fit: cover;
		}
	}
}
.post-photo-component {
	width: 100%;
	& .relative {
		display: flex;
		padding-left: 50px;
		padding-right: 50px;
		margin-bottom: 10px;
		@media (max-width: 380px) and (max-height: 670px) {
			padding-left: 95px;
			padding-right: 95px;
		}
		@media (min-width: $screen-desktop) {
			padding-left: 0;
			padding-right: 0;
			margin: 0;
			overflow: hidden;
		}
	}
	@media (min-width: $screen-desktop) {
		width: 100%;
		height: 100%;
		margin-right: 24px;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}
}
</style>
