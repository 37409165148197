<template>
	<dialog-component class="sub-limit-dialog" @close="$emit('close')">
		<template #header-title>
			<slot name="title" />
		</template>

		<slot v-if="!isLoading" />
		<template v-if="!isLoading" #dialog-footer>
			<div class="confirmation-dialog-buttons">
				<ButtonComponent color="white" @click="$emit('decline')">
					{{ btnDeclineText }}
				</ButtonComponent>
				<ButtonComponent color="transparent" :disabled="disabled" @click="$emit('accept')">
					{{ btnAcceptText }}
				</ButtonComponent>
			</div>
		</template>

		<div v-else class="sub-limit-waiting">
			<div class="i-spinner rotating text-white" />
			<div>Нужно немножко подождать.</div>
		</div>
	</dialog-component>
</template>

<script>
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'

export default {
	name: 'SubLimitDialog',
	components: { DialogComponent, ButtonComponent },
	props: {
		btnAcceptText: {
			type: String,
			default: 'Да'
		},
		btnDeclineText: {
			type: String,
			default: 'Нет'
		},
		isLoading: {
			type: Boolean,
			default: () => false
		},
		disabled: {
			type: Boolean,
			default: () => false
		}
	}
}
</script>

<style lang="scss">
.sub-limit-dialog {
	color: var(--dark-content-secondary);
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	& .dialog {
		min-height: 478px;
	}
	&-buttons {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	& .sub-limit {
		& .top-section {
			display: flex;
			align-items: center;
			& .user-avatar {
				margin-right: 10px;
			}
			& .user-descr {
				color: #191c22;
				background-color: #dfdfed;
				padding: 4px 8px;
				border-radius: 3px 12px 12px 12px;
			}
		}
		&-list {
			margin-top: 20px;
			& img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 12px;
			}
			& .placeholder-bg {
				background-image: url('@/assets/images/model-avatar-default.png');
				background-size: 140%;
				background-repeat: no-repeat;
				background-position: center;
			}
		}
		&-waiting {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			.i-spinner {
				display: inline-flex;
				font-size: 80px;
				width: 80px;
				margin: 100px 0;
			}
		}
	}
}
</style>
