<template functional>
	<div class="create-post-video-processing" v-bind="data.attrs" v-on="listeners">
		<slot />
	</div>
</template>

<script>
export default {
	name: 'VideoProcessingNotice'
}
</script>

<style lang="scss">
.create-post-video-processing {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 210px;
	text-align: center;
	background: rgba(25, 28, 34, 0.5);
	border-radius: 20px;
	color: #fff;
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	transform: translate(-50%, -50%);
	padding: 10px 20px;
	@media (max-width: 380px) and (max-height: 670px) {
		max-width: 130px;
	}
	@media (max-width: 380px) {
		font-size: 11px;
		width: auto;
		padding: 10px 10px;
	}
}
</style>
