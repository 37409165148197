<template>
	<div :class="['post-video-component post-media-data']">
		<input-media ref="mediaInputVideo" :rules="{ minDimensions: [1000, 217] }" format-file="video/*" />
		<dialog-component v-if="showCoverModal & isMobileDevice" class="inner-dialog" @close="closeCoverModal">
			<template #header-title>Изменить обложку</template>
			<choose-cover v-if="showCoverModal" @close="closeCoverModal" :video-preview="videoPreview" :previews="previews" />
		</dialog-component>
		<choose-cover
			v-if="showCoverModal & !isMobileDevice"
			:is-desktop="true"
			:video-preview="videoPreview"
			:previews="previews"
			@close="closeCoverModal"
			:pop-up-size-width="popUpSizeWidth"
		/>
		<edit-video
			v-if="editVideoModal & !isMobileDevice"
			:video-preview="videoPreview"
			:is-desktop="true"
			:its-horizontal-video="itsHorizontalVideo"
			@close="closeEditVideoModal"
			@changeVideo="openMediaChoose"
		/>
		<template v-if="(preview_url || videoAsLink) && !showCoverModal && !editVideoModal">
			<div
				:class="['create-post-preview relative fix-scroll', { 'create-post-preview-horizontal': itsHorizontalVideo }]"
			>
				<template v-if="videoAsLink">
					<button type="button" @click="changePlayVideo" class="video-controll">
						<span v-show="videoIsPause" class="video-controll--play video-controll-icon" />
						<span v-show="!videoIsPause" class="video-controll--pause video-controll-icon" />
					</button>
					<video
						ref="videoBlock"
						:src="videoAsLink"
						:class="['w-full create-post-video', { 'create-post-video--loaded': videoPreviewLoaded }]"
						playsinline
						muted
						loop
						:poster="videoPreview"
						@loadeddata="onLoadVideo"
					/>
				</template>
				<template v-if="preview_url && !videoAsLink">
					<upload-image-preview :src="preview_url" @load="onLoadPreview" />
					<video-processing-notice> Видео обрабатывается</video-processing-notice>
				</template>
				<div class="edit-video-controls">
					<div v-if="!isVideoProcessing" class="create-post-edit-preview">
						<button type="button" class="create-post-control button-flex" @click="onEditPreview">
							<span class="icon-cover" />
							<span>Изменить обложку</span>
						</button>
					</div>
					<div v-if="videoAsLink" class="create-post-image-controls">
						<button type="button" class="create-post-control button-flex" @click="openEditVideoModal">
							<i class="i-pen" />
							<span>Редактировать</span>
						</button>
					</div>
				</div>
			</div>
		</template>

		<dialog-component v-if="editVideoModal & isMobileDevice" class="inner-dialog" @close="closeEditVideoModal">
			<template #header-title>Редактирование</template>
			<edit-video
				:video-preview="videoPreview"
				:its-horizontal-video="itsHorizontalVideo"
				@close="closeEditVideoModal"
				@changeVideo="openMediaChoose"
			/>
		</dialog-component>
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useVideoUploadStore } from '@/stores/videoUpload'
import { useCreatePostStore } from '@/stores/createPost'
import { useCommonStore } from '@/stores/common'
import { useModalStore } from '@/stores/modal'
import UploadImagePreview from '@/components/UploadImagePreview.vue'
import VideoProcessingNotice from '@/components/VideoProcessingNotice.vue'
import DialogComponent from '@/components/dialogs/DialogComponent.vue'
import InputMedia from '@/components/create-post/InputMedia.vue'
import ChooseCover from '@/components/create-post/ChooseCover.vue'
import EditVideo from '@/components/create-post/EditVideo.vue'

export default {
	name: 'CreateVideoMedia',
	components: {
		VideoProcessingNotice,
		UploadImagePreview,
		DialogComponent,
		InputMedia,
		ChooseCover,
		EditVideo
	},
	data() {
		return {
			currentSlide: 0,
			videoIsPause: true,
			itsHorizontalVideo: false,
			videoPreviewLoaded: false
		}
	},
	updated() {
		this.checkLoadedPreview()
	},
	computed: {
		...mapState(useCreatePostStore, [
			'isCreating',
			'previews',
			'preview_url',
			'videoAsLink',
			'post',
			'postId',
			'isVideoProcessing'
		]),
		...mapState(useModalStore, ['showCoverModal', 'editVideoModal', 'popUpSizeWidth']),
		...mapState(useCommonStore, ['isMobileDevice']),
		hasPreviews() {
			if (this.previews) {
				return this.previews.length !== 0
			}
			return false
		},
		videoPreview() {
			return this.preview_url || this.post.video_preview_url
		}
	},
	methods: {
		...mapActions(useCreatePostStore, ['choosePreview', 'resetVideo', 'getVideoPreview']),
		...mapActions(useVideoUploadStore, ['deleteVideo', 'initUploader', 'addFile']),
		...mapActions(useModalStore, [
			'openCoverModal',
			'closeCoverModal',
			'openEditVideoModal',
			'closeEditVideoModal',
			'setResizePopUp'
		]),
		changePlayVideo() {
			if (this.videoIsPause) {
				this.$refs.videoBlock.play()
			} else {
				this.$refs.videoBlock.pause()
			}
			this.videoIsPause = !this.videoIsPause
		},
		openMediaChoose() {
			this.$refs.mediaInputVideo.$refs.media.value = ''
			this.$refs.mediaInputVideo.$refs.media.click()
		},
		onReset() {
			if (this.isCreating) {
				this.deleteVideo(this.post.video_id)
			}
			this.currentSlide = 0
			this.resetVideo()
		},
		checkLoadedPreview() {
			if (this.videoPreview) {
				const img = new Image()
				img.onload = () => {
					this.videoPreviewLoaded = true
					this.itsHorizontalVideo = img.height > img.width
				}
				img.src = this.videoPreview
			}
		},
		onEditPreview() {
			if (!this.hasPreviews) {
				this.getVideoPreview(this.postId)
			}
			this.openCoverModal()
		},
		onLoadPreview() {
			if (this.$refs.previewImg) {
				this.setResizePopUp(
					{ naturalWidth: this.$refs.previewImg.naturalWidth, naturalHeight: this.$refs.previewImg.naturalHeight },
					416
				)
			}
		},
		onLoadVideo() {
			if (this.$refs.videoBlock) {
				this.setResizePopUp(
					{ naturalWidth: this.$refs.videoBlock.videoWidth, naturalHeight: this.$refs.videoBlock.videoHeight },
					416
				)
			}
		}
	},
	mounted() {
		if (this.hasPreviews) {
			this.choosePreview(0)
		}
		this.checkLoadedPreview()
	}
}
</script>

<style lang="scss" scoped>
.icon-cover::before {
	content: '';
	display: inline-block;
	width: 17px;
	height: 17px;
	background-image: url('@/assets/images/icons/img.svg');
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	margin-right: 2px;
}

.button-flex {
	align-items: center;
}

.video-controll {
	width: 50px;
	height: 50px;
	border-radius: 25px;
	background-color: rgba(25, 28, 34, 0.5);
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 10;
	border: none;
}

.video-controll-icon {
	width: 100%;
	height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
}

.video-controll--play {
	background-image: url('@/assets/images/icons/play.svg');
}

.video-controll--pause {
	background-image: url('@/assets/images/icons/pause.svg');
}

.create-post-preview-horizontal {
	padding-left: 50px;
	padding-right: 50px;
	@media (min-width: $screen-desktop) {
		padding: 0;
	}
}

.create-post-video {
	opacity: 0;
}

.create-post-video--loaded {
	opacity: 1;
}

.post-video-component {
	@media (min-width: $screen-desktop) {
		margin-right: 24px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		width: 100%;
	}
}
.create-post-edit-preview::v-deep {
	position: absolute;
	top: 11px;
	left: 0;
	right: 0;
	z-index: 5;
	display: flex;
	justify-content: center;
	gap: 13px;
	margin: auto;
	width: 100%;
	@media (min-width: $screen-desktop) {
		position: relative;
		top: auto;
		width: auto;
		margin: 0;
	}
	& .create-post-control {
		padding: 5px 10px;

		& .icon-cover {
			margin-left: 0;
		}
	}
}

// .create-post-image-controls {
// 	position: absolute;
// 	bottom: 20px;
// 	left: 0;
// 	right: 0;
// 	z-index: 5;
// 	display: flex;
// 	justify-content: center;
// 	gap: 13px;
// 	margin: auto;
// 	width: 100%;
// 	& .create-post-control {
// 		padding: 9px;
// 		@media (min-width: $screen-desktop) {
// 			padding: 5px 9px;
// 		}
// 	}
// 	@media (min-width: $screen-desktop) {
// 		position: relative;
// 		bottom: 0;
// 		width: auto;
// 		margin: 0;
// 	}
// }

// .edit-video-controls {
// 	@media (min-width: $screen-desktop) {
// 		position: absolute;
// 		bottom: 10px;
// 		width: 100%;
// 		left: 0;
// 		display: flex;
// 		justify-content: center;
// 		gap: 20px;
// 	}
// }

.create-post-preview {
	width: 100%;
	margin-bottom: 10px;
	@media (min-width: $screen-desktop) {
		height: 100%;
		margin-bottom: 0;
	}
}
</style>
