<template>
	<div>
		<div class="fixed-top hide-desktop">
			<div class="header flex row space container">
				<header-logo />
			</div>
		</div>
		<div class="content">
			<slot />
		</div>
	</div>
</template>

<script>
import { mapState } from 'pinia'
import { useModalStore } from '@/stores/modal'
import HeaderLogo from '@/components/HeaderLogo.vue'

export default {
	name: 'HomeLayout',
	components: { HeaderLogo },
	computed: {
		...mapState(useModalStore, ['balanceNotification'])
	}
}
</script>

<style scoped lang="scss">
.content {
	@media (min-width: $screen-desktop) {
		max-width: 444px;
		padding-left: 0;
	}
}
</style>
