import Vue from 'vue'
import Pusher from 'pusher-js/with-encryption'
// eslint-disable-next-line import/no-extraneous-dependencies
import Echo from 'laravel-echo'
import { useMessengerStore } from '@/stores/messenger'
import AppConfig from '@/config.class'

window.Pusher = Pusher

function connect() {
	window.Echo = new Echo({
		broadcaster: 'reverb',
		key: AppConfig.reverbAppKey,
		wsHost: AppConfig.reverbHost,
		wsPort: AppConfig.reverbPort,
		wssPort: AppConfig.reverbPort,
		forceTLS: AppConfig.reverbScheme,
		enabledTransports: ['ws', 'wss'],
		authEndpoint: AppConfig.broadcastingAuth,
		auth: {
			headers: {
				Authorization: `Bearer ${Vue.auth.token()}`
			}
		}
	})
}

function getChannelName(id) {
	if (Vue.auth.check('girl')) {
		return `messenger.girl.${id}`
	}
	return `messenger.consumer.${id}`
}

function subscribe(id) {
	const channel = window.Echo.private(getChannelName(id))
	channel.listen('.new.message', (data) => {
		useMessengerStore().newMessage(data, id)
	})

	channel.listen('.message.archived', (data) => {
		useMessengerStore().removeRoomMessage(data)
	})

	channel.listen('.thread.archived', (data) => {
		useMessengerStore().removeThread(data)
	})

	channel.listen('.new.thread', () => {
		useMessengerStore().newThread()
	})
	channel.listen('.thread.read', (data) => {
		useMessengerStore().threadRead(data)
	})
	channel.listen('.paid-message.content-ready', (data) => {
		useMessengerStore().renewPaidMessage(data.messenger_thread_uuid, data.messenger_message_uuid)
	})

	channel.listen('.paid-message.payment-status-update', (data) => {
		useMessengerStore().renewPaidMessage(data.messenger_thread_uuid, data.messenger_message_uuid)
	})
	channel.listen('.unread_threads_count', (data) => {
		useMessengerStore().setUnreadThreadsCount(data.unread_threads_count)
	})
}

function subscribeThread(id) {
	const channel = window.Echo.channel(`presence-messenger.thread.${id}`)
	channel.listen('.message.edited', (data) => {
		useMessengerStore().editMessage(data)
	})
	channel.listen('.thread.avatar', (data) => {
		useMessengerStore().threadAvatar(data)
	})
	channel.listen('.reaction.added', (data) => {
		useMessengerStore().updateReactions(data)
	})
	channel.listen('.reaction.removed', (data) => {
		useMessengerStore().updateReactions(data)
	})
}

function checkConnection() {
	return window.Echo
}

export default { connect, subscribe, subscribeThread, checkConnection }
