function mediaContentVisibleOnlyForFans(posts, girlId) {
	posts
		.filter(({ author }) => author.id === girlId)
		.map(({ author }) => {
			author.has_in_fans_current_consumer = false
			return author
		})
}

function medialContentVisibleForAllConsumers(posts, girlId) {
	posts
		.filter(({ author }) => author.id === girlId)
		.map(({ author }) => {
			author.has_in_fans_current_consumer = true
			return author
		})
}

function turnOnWaitingSubscription(posts, girlId) {
	posts
		.filter(({ author }) => author.id === girlId)
		.map(({ author }) => {
			author.waiting_subscription_result = true
			return author
		})
}

function findPostAndUpdateLikesValue(posts, post) {
	if (posts) {
		const likedPost = posts.data.find((el) => el.id === post.id)
		if (likedPost) {
			likedPost.total_likes = post.likes
			likedPost.total_consumer_likes += 1
		}
	}
}

function findPostAndUpdateLikedComment(posts, postId) {
	if (posts) {
		posts.forEach((val) => {
			const likedPost = val?.data.find((el) => el.id === postId)
			if (likedPost) {
				likedPost.girl_liked_any_comment = true
			}
		})
	}
}

function removePostFromList(postsList, postId) {
	if (postsList) {
		const index = postsList.data.findIndex((post) => post.id === postId)
		if (index !== -1) {
			postsList.data.splice(index, 1)
		}
	}
}

function arrayUnique(array, field = null) {
	if (field) {
		if (!array[0][field]) {
			return array
		}
		return array.filter((n, i, a) => n === a.find((m) => m[field] === n[field]))
	}
	return Array.from(new Set(array))
}

async function getMoreData(content, loadFunction) {
	let link = content.links.next
	if (
		content.meta.path.substring(content.meta.path.lastIndexOf('/') + 1) === 'recommended' &&
		content.meta.current_page === content.meta.last_page
	) {
		link = `${content.meta.path}?section=regular`
		if (content.links.last.includes('?section=regular')) {
			link = null
		}
	}
	if (!link) {
		return
	}
	try {
		const { data, links, meta } = await loadFunction(link)
		content.data = arrayUnique([...content.data, ...data], 'id')
		content.links = links
		content.meta = meta
	} catch (error) {
		// eslint-disable-next-line
		console.trace(error)
		throw error
	}
}

export { getMoreData, findPostAndUpdateLikedComment }

export default {
	mediaContentVisibleOnlyForFans,
	medialContentVisibleForAllConsumers,
	findPostAndUpdateLikesValue,
	turnOnWaitingSubscription,
	removePostFromList
}
