<template>
	<div class="btn-wrap">
		<button
			type="button"
			@click="onClick"
			:class="[btnClass, { invert: isSubscribed }]"
			:disabled="isWaitingSubscriptionResult"
		>
			<template v-if="isWaitingSubscriptionResult">
				<div class="i-spinner post-subscribe-spinner rotating" />
			</template>
			<template v-else>
				{{ subscribedText }}
			</template>
		</button>
		<confirmation-dialog
			v-if="dialogVisible"
			btn-accept-text="Да, отписаться"
			btn-decline-text="Нет, передумал"
			@accept="onUnsubscribe"
			@decline="hideDialog"
			@close="hideDialog"
		>
			<template #title>Вы хотите отписаться?</template>
			Тогда вы не сможете видеть новые посты модели и не будете получать взаимность
		</confirmation-dialog>
		<sub-limit-dialog
			v-if="dialogSubLimitVisible"
			btn-accept-text="Да, отписаться"
			btn-decline-text="Нет, передумал"
			:disabled="isWaitingPickGirl"
			:is-loading="isLoading"
			@accept="onUnsubscribeSubLimit"
			@decline="hideDialogSubLimit"
			@close="hideDialogSubLimit"
		>
			<template #title>Ограничение подписок</template>
			<div class="sub-limit">
				<div class="top-section">
					<div class="user-avatar" :style="{ 'background-image': `url('${profile?.avatar || avatar}')` }" />
					<div class="user-descr">
						Чтобы стать моим фанатом, тебе придется сначала отказаться от подписки на одну из этих моделей:
					</div>
				</div>
				<div class="sub-limit-list history flex row start">
					<div v-for="girl in subscriptionsData?.data?.girls" :key="`sl${girl.id}`" v-bind="girl">
						<div
							@click="onSelect(girl.id)"
							class="history-item"
							:class="{
								active: girl.id === pickGirlId
							}"
						>
							<div class="image-container relative">
								<div class="image placeholder-bg">
									<img v-if="girl.avatar_url" :src="girl.avatar_url" alt="" />
								</div>
							</div>
							<div class="history-item-name">{{ girl.model_name }}</div>
						</div>
					</div>
				</div>
			</div>
		</sub-limit-dialog>
	</div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGirlStore } from '@/stores/girl'
import { useHomeStore } from '@/stores/home'
import { useConsumerStore } from '@/stores/consumer'
import { useSearchStore } from '@/stores/search'
import { useProfileStore } from '@/stores/profile'
import { toDayAndMonth } from '@/helpers/dateFormat'
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue'
import SubLimitDialog from '@/components/dialogs/SubLimitDialog.vue'

export default {
	name: 'SubscribeButton',
	components: { ConfirmationDialog, SubLimitDialog },
	props: {
		id: { type: Number, default: 0 },
		isGirlProfile: { type: Boolean, default: false },
		isSubscribed: { type: Boolean, default: false },
		isWaitingSubscriptionResult: { type: Boolean, default: false },
		isSubscribeRenewable: { type: Boolean, required: true },
		subscribedTill: { type: [String, null], default: null },
		btnClass: { type: String, default: '' },
		avatar: { type: String, default: '' }
	},
	data() {
		return {
			dialogVisible: false,
			dialogSubLimitVisible: false,
			pickGirlId: null,
			isWaitingPickGirl: true
		}
	},
	computed: {
		...mapState(useProfileStore, {
			consumerId: 'id',
			subscriptionsData: 'subscriptionsData'
		}),
		...mapState(useGirlStore, { profile: 'profile', isLoading: 'isLoading' }),
		subscribeLink() {
			const host = this.$config.apiUrl
			return `${host}consumers/${this.consumerId}/girls/${this.id}/subscribe`
		},
		subscribedText() {
			if (!this.isSubscribed) {
				if (
					this.$config.showSubscriptionPriceInGirlProfile &&
					this.$config.girlSubscriptionPrice &&
					this.isGirlProfile
				) {
					return `Подписаться за ${this.$config.girlSubscriptionPrice} руб. в месяц`
				}
				return `Подписаться`
			}
			if (this.isSubscribeRenewable) {
				return 'Вы подписаны'
			}
			return `Действует до ${toDayAndMonth(this.subscribedTill)}`
		}
	},
	methods: {
		...mapActions(useProfileStore, ['getFollowingGirls']),
		...mapActions(useGirlStore, {
			removeBlurFromGirlPosts: 'removeBlurFromPosts',
			applyBlurToGirlPostsOnlyForFans: 'applyBlurToPostsOnlyForFans',
			turnOnWaitingSubscriptionForGirlPost: 'turnOnWaitingSubscription',
			subscribe: 'subscribe',
			unsubscribe: 'unsubscribe'
		}),
		...mapActions(useHomeStore, {
			resetFilter: 'resetFilter',
			removeBlurFromHomePosts: 'removeBlurFromPosts',
			applyBlurToHomePostsOnlyForFans: 'applyBlurToPostsOnlyForFans',
			turnOnWaitingSubscriptionForHomePost: 'turnOnWaitingSubscription'
		}),
		...mapActions(useConsumerStore, {
			removeBlurFromConsumerPosts: 'removeBlurFromPosts',
			applyBlurToConsumerPostsOnlyForFans: 'applyBlurToPostsOnlyForFans',
			turnOnWaitingSubscriptionForConsumerPost: 'turnOnWaitingSubscription'
		}),
		...mapActions(useSearchStore, {
			removeBlurFromSearchPosts: 'removeBlurFromPosts',
			applyBlurToSearchPostsOnlyForFans: 'applyBlurToPostsOnlyForFans',
			turnOnWaitingSubscriptionForSearchPost: 'turnOnWaitingSubscription'
		}),
		showDialog() {
			this.dialogVisible = true
		},
		hideDialog() {
			this.dialogVisible = false
		},
		showDialogSubLimit() {
			this.dialogSubLimitVisible = true
		},
		hideDialogSubLimit() {
			this.dialogSubLimitVisible = false
		},
		onClick() {
			if (!this.isSubscribeRenewable) {
				return
			}
			if (this.isSubscribed) {
				this.showDialog()
				return
			}
			if (this.$config.subsModelLimit) {
				if (this.subscriptionsData?.data?.girls?.length >= this.$config.subsModelLimit) {
					this.showDialogSubLimit()
					return
				}
			}

			window.location.href = this.subscribeLink
		},
		async onUnsubscribe() {
			await this.unsubscribe(this.id)
			this.applyBlurToGirlPostsOnlyForFans(this.id)
			this.applyBlurToHomePostsOnlyForFans(this.id)
			this.applyBlurToConsumerPostsOnlyForFans(this.id)
			this.applyBlurToSearchPostsOnlyForFans(this.id)
			this.turnOnWaitingSubscriptionForGirlPost(this.id)
			this.turnOnWaitingSubscriptionForHomePost(this.id)
			this.turnOnWaitingSubscriptionForConsumerPost(this.id)
			this.turnOnWaitingSubscriptionForSearchPost(this.id)
			await this.getFollowingGirls()
			await this.resetFilter()
			this.hideDialog()
		},
		async onUnsubscribeSubLimit() {
			try {
				await this.unsubscribe(this.pickGirlId)
			} finally {
				this.hideDialogSubLimit()
				window.location.href = this.subscribeLink
			}
		},
		onSelect(id) {
			this.pickGirlId = id
			this.isWaitingPickGirl = false
		}
	}
}
</script>
