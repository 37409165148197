<template>
	<div class="create-post-fieldwrap">
		<div
			:class="['create-post-field', { 'create-post-field--disable': audienceFieldDisable }]"
			@click="openAudienceModal"
		>
			<span class="create-post-field-withicon create-post-field-withicon--privation">Приватность</span>
			<span class="color-red create-post-field--chevron">{{ hasAttachedMedia ? postAudience : '' }}</span>
		</div>
		<div v-if="scheduledPostsEnabled">
			<div :class="['create-post-field', { 'create-post-field--disable': scheduledFieldDisable }]">
				<span class="create-post-field-withicon create-post-field-withicon--date" @click="openCalendar">
					<span v-if="!datePublic">Дата и время публикации</span>
					<span v-else>Публикация</span>
				</span>
				<span @click="openCalendar" :class="['color-red', { 'create-post-field--chevron': !datePublic }]">{{
					datePublic
				}}</span>
				<span class="create-post-field--cross i-cross" @click="removeDatePublic" v-if="datePublic" />
			</div>
			<div
				:class="['create-post-field', { 'create-post-field--disable': notificationFieldDisable }]"
				@click="changeNotification"
			>
				<span class="create-post-field-withicon create-post-field-withicon--notification">
					Уведомлять о выходе поста
				</span>
				<span :class="['switcher', { 'switcher--on': getNotification }]" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PostFieldsGroup',
	props: {
		showModal: {
			type: Boolean,
			default: false
		},
		hasAttachedMedia: {
			type: Boolean,
			default: false
		},
		canBeScheduled: {
			type: Boolean,
			default: false
		},
		datePublic: {
			type: String,
			default: ''
		},
		postAudience: {
			type: String,
			default: ''
		},
		getNotification: {
			type: Boolean,
			default: false
		},
		scheduledPostsEnabled: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		audienceFieldDisable() {
			return !this.hasAttachedMedia || this.showModal
		},
		scheduledFieldDisable() {
			return !this.canBeScheduled || !this.hasAttachedMedia || this.showModal
		},
		notificationFieldDisable() {
			return !this.datePublic || !this.canBeScheduled || this.showModal
		}
	},
	methods: {
		openAudienceModal() {
			if (!this.audienceFieldDisable) {
				this.$emit('openAudienceModal')
			}
		},
		openCalendar() {
			if (!this.scheduledFieldDisable) {
				this.$emit('openCalendar')
			}
		},
		changeNotification() {
			if (!this.notificationFieldDisable) {
				this.$emit('changeNotification')
			}
		},
		removeDatePublic() {
			if (!this.scheduledFieldDisable) {
				this.$emit('removeDatePublic')
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.create-post-field {
	font-size: 16px;
	padding-top: 12px;
	padding-bottom: 12px;
	display: flex;
	color: #bebec9;
	justify-content: space-between;
	cursor: pointer;
	align-items: center;
	transition: $transition-color;
	&:not(.create-post-field--disable):hover {
		color: #fff;
		& .switcher::before {
			background-color: #fff;
		}
	}
}

.create-post-field-withicon {
	display: flex;
	align-items: center;
}

.create-post-field-withicon::before {
	content: '';
	width: 20px;
	height: 20px;
	margin-right: 10px;
	background-repeat: no-repeat;
}

.create-post-field-withicon--privation::before {
	background: transparent url('@/assets/images/icons/eye.svg') no-repeat center / cover;
}
.create-post-field-withicon--date {
	flex: 1;
}
.create-post-field-withicon--date::before {
	background: transparent url('@/assets/images/icons/calendar-days.svg') no-repeat center / cover;
}

.create-post-field-withicon--notification::before {
	background: transparent url('@/assets/images/icons/bell.svg') no-repeat center / cover;
}

.create-post-fieldwrap > div > .create-post-field:first-child {
	border-top: 1px solid rgba(67, 72, 86, 0.5);
	border-bottom: 1px solid rgba(67, 72, 86, 0.5);
}

.color-red {
	color: #ed326a;
}

.create-post-field--disable {
	cursor: default;
	opacity: 30%;
}

.create-post-field--chevron {
	display: flex;
	align-items: center;
	padding-right: 7px;
}

.create-post-field--chevron::after {
	width: 17px;
	height: 24px;
	content: '';
	background: transparent url('@/assets/images/icons/chevron.svg') no-repeat center / cover;
	margin-left: 5px;
}

.switcher {
	display: flex;
	height: 20px;
	width: 37px;
	background-color: rgba(114, 114, 129, 0.5);
	border-radius: 14px;
	transition: $transition-background;
	margin-right: 5px;
}

.switcher::before {
	content: '';
	width: 20px;
	height: 20px;
	border-radius: 14px;
	background-color: #bebec9;
	transition: $transition-transform, $transition-background;
}

.switcher--on {
	background-color: rgba(237, 50, 106, 0.5);
}

.switcher--on::before {
	transform: translateX(20px);
}

.create-post-field--cross {
	color: #727281;
	font-size: 24px;
	margin-left: 5px;
}
</style>
