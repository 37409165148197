<template>
	<div class="photo-verification">
		<div class="relative photo-verification-preview">
			<upload-image-preview class="" :src="image || imageExample" />
			<div v-if="isLoadingImage" class="verification-loader">
				<i class="i-spinner rotating text-white" />
			</div>
			<slot />
		</div>
		<p class="device-error" v-if="showDeviceTypeError">Упс, ошибка... Попробуй с телефона</p>
		<input-image
			:id="id"
			capture="user"
			@attached="showImage"
			@loadstart="isLoadingImage = true"
			@loadend="isLoadingImage = false"
			@input="$emit('input', $event)"
		/>
		<div class="photo-verification-description">
			<p v-if="comment" class="text-red mb-8">{{ comment }}</p>
			<p v-if="description">{{ description }}</p>
		</div>
		<label
			:for="id"
			class="mb-8 btn"
			@click="onInput($event)"
			:class="hasPhotoAttached ? 'btn-transparent' : 'btn-primary'"
		>
			{{ selectBtnText }}
		</label>
		<button-component
			:disabled="!hasPhotoAttached"
			:loading="isSending"
			:color="hasPhotoAttached ? 'primary' : 'transparent'"
			@click="$emit('submit')"
		>
			Следующий шаг
		</button-component>
	</div>
</template>

<script>
import UploadImagePreview from '@/components/UploadImagePreview.vue'
import InputImage from '@/components/forms/InputImage.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import { mapState } from 'pinia'
import { useCommonStore } from '@/stores/common'

export default {
	name: 'PhotoVerification',
	components: {
		InputImage,
		UploadImagePreview,
		ButtonComponent
	},
	props: {
		id: { type: String, default: '' },
		comment: { type: String, default: '' },
		description: { type: String, default: '' },
		imageExample: { type: String, default: '' },
		isSending: { type: Boolean, default: false }
		// submitBtnText: { type: String, default: 'Отправить' }
	},
	data() {
		return {
			image: '',
			isLoadingImage: false,
			hasPhotoAttached: false,
			showDeviceTypeError: false
		}
	},
	computed: {
		...mapState(useCommonStore, ['isMobileDevice']),
		selectBtnText() {
			return this.hasPhotoAttached ? 'Сделать другое фото' : 'Сделать фото'
		}
	},
	methods: {
		onInput(e) {
			if (!this.isMobileDevice) {
				e.preventDefault()
				this.showDeviceTypeError = true
			}
		},
		showImage(e) {
			this.image = e
			this.hasPhotoAttached = true
		}
	}
}
</script>

<style>
.verification-loader {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.4);
	font-size: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
}

.photo-verification-preview img {
	width: 100%;
	height: auto;
}

.photo-verification-description {
	padding: 16px 0 36px;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
}

.device-error {
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	margin-top: 6px;
	color: var(--red);
}
</style>
