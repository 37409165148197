<template functional>
	<div class="avatar-circle placeholder-bg" v-bind="data.attrs" v-on="listeners">
		<img v-lazy="props.imagePath" src="#" alt="" />
	</div>
</template>

<script>
export default {
	name: 'AvatarCircle',
	props: {
		imagePath: {
			type: String,
			required: true
		}
	}
}
</script>

<style lang="scss">
.avatar-circle {
	border-radius: 100%;
	background-size: 100%;
	overflow: hidden;
	width: 40px;
	height: 40px;
	min-width: 40px;
	min-height: 40px;

	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
</style>
